<template>
  <!-- text-color="default-pink" -->

  <q-btn
    class="btn-content"
    rounded
    no-caps
    dense
    unelevated
    outline
    @click="$emit('addNewPost')"
  >
    <span v-show="showIcon">
        <q-img
          width="18px"
          :src="require(`@/assets/icons/${icon}.svg`)"          
          spinner-color="white"
        />
      </span>
    <p>{{ titleButton }}</p>
  </q-btn>
</template>


<script>
export default {
  name: "OutlineBtn",
  props: {
    titleButton: String,
    showIcon: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: String,
      default: "plus",
    },
  },
};
</script>


<style lang="scss" scoped>

.btn-content{
  color: var(--q-secundary);
}

button {
  padding: 8px 15px;

  ::before {
    box-shadow: none;
  }
  .icon-left{
    margin-right:8px
  }
}

button span {
  margin-right: 5px;
  padding: 2px;
  border-radius: 50%;
  width: 25px;
}

button p {
  font-size: 0.8rem;
  font-weight: 600;
  margin: 0;
}
</style>
import http from '../http-common'

export default class PointServices {
    async getEvent(eventId) {
        return await http.get(`v1/point/${eventId}`);
    }

    async addPoint(data) {
        return await http.post(`v1/point/add-point`, data);
    }
}
<template>
  <div class="flex column items-center full-width">
    <section>
      <div class="header-section">
        <div class="btn-add-section q-pr-sm flex row">
          <label class="q-pa-sm">Criar seção</label>
          <q-btn round unelevated size="sm" color="white" text-color="default-pink" icon="add" @click="addSection()" />
        </div>
        <div class="q-ml-xl btn-more-section">
          <q-btn round flat size="sm" color="default-pink" icon="chevron_left" @click="handleScrollleft()" />
        </div>
        <div class="section-labels" id="LevelScroll">
          <div v-for="(section, index) in wiki.contents" :key="index" @click="Selectindex(index)"
            :class="['section-label', index == selectedIndex ? 'section-label-selected' : 'section-label-unselected', 'q-ml-sm']">
            <p>seção {{ index + 1 }}</p>
          </div>
        </div>
        <div class="btn-more-section">
          <q-btn round flat size="sm" color="default-pink" icon="chevron_right" @click="handleScrollRight()" />
        </div>
      </div>
      <main>
        <div class="flex row items-center">
          <q-input class="input-section q-pl-sm" square borderless type="text" ref="titleasd" :dense="true"
            placeholder="Informe o titulo da seção" color="default-pink"
            v-model="wiki.contents[selectedIndex].section_Title" :rules="[]" />
          <div class="btn-more-section">
            <q-btn padding="sm" size="15px" unelevated square color="default-pink" icon="delete"
              @click="deleteSection()" />

          </div>
        </div>

        <div class="quillEditorContainer">
          <div class="quillEditor">


            <QuillEditor :modules="modules" toolbar="full" theme="snow" contentType="html" ref="quill"
              v-model:content="currentEdit"
              @update:content="() => { wiki.contents[selectedIndex].section_Content = currentEdit; }">
            </QuillEditor>

          </div>
        </div>
      </main>
    </section>
    <div class="flex column items-end full-width">
      <slot name="action-btn" v-bind:props="formRef"></slot>
    </div>
  </div>
</template>

<style>

</style>

<script>
//----QUILL
import { ImageDrop } from 'quill-image-drop-module';
import BlotFormatter from 'quill-blot-formatter';

//----VUEJS
import { ref } from "vue";

export default {
  name: "ContentWiki",
  props: {
    wiki: Object,
  },
  components: {

  },
  emits: ['addSection', 'Selectindex', 'deleteSection'],
  setup(props) {
    const quill = ref(null);
    const titleasd = ref(null);
    const modules = ref([
      {
        name: 'ImageDrop',
        module: ImageDrop,
        options: {/* options */ }
      },
      {
        name: 'BlotFormatter',
        module: BlotFormatter,
        options: {/* options */ }
      },
    ]);

    let selectedIndex = ref(0);
    let currentEdit = ref(props.wiki.contents[0].section_Content);

    function addSection() {
      props.wiki.contents.push({
        section_Content: "",
        section_Title: "",
      });
    }

    function Selectindex(index) {
      selectedIndex.value = index;
      quill.value.setHTML(props.wiki.contents[selectedIndex.value].section_Content);
    }

    function deleteSection() {
      let arraySize = props.wiki.contents.length - 1;
      if (arraySize > 0) {
        if (selectedIndex.value == arraySize) {
          props.wiki.contents[selectedIndex.value].section_Title = props.wiki.contents[selectedIndex.value - 1].section_Title;
          Selectindex(selectedIndex.value - 1);

        } else {
          props.wiki.contents[selectedIndex.value].section_Title = props.wiki.contents[selectedIndex.value + 1].section_Title;
          Selectindex(selectedIndex.value + 1);

        }
        if (selectedIndex.value > -1) {
          props.wiki.contents.splice(selectedIndex.value, 1);
        }
      }
    }

    function handleScrollleft() {
      var myElement = document.getElementById("LevelScroll");
      if ((myElement.scrollLeft -= 300 < myElement.scrollWidth)) {
        myElement.scrollLeft -= 300;
      } else {
        myElement.scrollLeft = 0;
      }
    }

    function handleScrollRight() {
      var myElement = document.getElementById("LevelScroll");
      if ((myElement.scrollLeft += 300 < myElement.scrollWidth)) {
        myElement.scrollLeft += 300;
      } else {
        myElement.scrollLeft = myElement.scrollWidth;
      }
    }

    return {
      selectedIndex,
      currentEdit,
      quill,
      titleasd,
      modules,

      //----FUNCTIONS
      addSection,
      Selectindex,
      handleScrollRight,
      handleScrollleft,
      deleteSection
    };
  },
};
</script>

<style lang="scss" scoped>
section {
  width: 85%;
}


.quillEditor {
  width: 60.5vw;
  display: flex;
  flex-direction: column;
}

.quillEditorContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ql-toolbar .ql-snow {
  background: var(--q-secundary) !important;
}

.header-section {
  display: flex;
  align-items: flex-end;
  width: 100%;

}

.section-labels {
  width: 40vw;
  display: flex;
  overflow-x: hidden;

  .section-label {
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;

    padding: 5px 27px;

    cursor: pointer;

    p {
      font-weight: 600;
      font-size: 12px;
      margin: 0;
    }
  }

  .section-label-unselected {
    border-top: 1px solid #A6A6A6;
    border-left: 1px solid #A6A6A6;
    border-right: 1px solid #A6A6A6;

    border-radius: 10px 10px 0 0;

    color: #a6a6a6;
  }

  .section-label-selected {
    border-top: 1px solid var(--q-secundary);
    border-left: 1px solid var(--q-secundary);
    border-right: 1px solid var(--q-secundary);

    border-radius: 10px 10px 0 0;

    color: var(--q-secundary);
  }

}

.btn-add-section {
  display: flex;
  align-items: center;

  background: var(--q-secundary);
  color: white;
  border-radius: 10px 10px 0 0;

  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
}

.btn-more-section {}

.input-section {
  flex: 1;
  border: 1px solid var(--q-secundary);

}
</style>

<template>
  <div class="flex column">
    <q-form ref="formRef">
      <div class="col-6 flex row q-pa-sm">
        <div class="form-input col-6 column">
          <div class="row">
            <span class="title-input-form"
              >Título <b title="Obrigatório" style="cursor: pointer">*</b></span
            >
          </div>
          <div class="row">
            <q-input
              class="col-11"
              bg-color="grey-3"
              v-model="wiki.title"
              color="default-pink"
              dense
              type="text"
              outlined
              lazy-rules
              :rules="[(val) => !!val || 'O campo título é obrigatório.']"
            />
          </div>
        </div>

        <div class="form-input col-6 column">
          <div class="row">
            <span class="title-input-form"
              >Descrição Curta
              <b title="Obrigatório" style="cursor: pointer">*</b></span
            >
          </div>
          <div class="row">
            <q-input
              class="col-11"
              bg-color="grey-3"
              v-model="wiki.description"
              color="default-pink"
              dense
              type="text"
              outlined
              :rules="[(val) => !!val || 'O campo descrição é obrigatório.']"
            />
          </div>
        </div>
        <div class="form-input col-6 column">
          <div class="row">
            <span class="title-input-form"
              >Categoria
              <b title="Obrigatório" style="cursor: pointer">*</b></span
            >
          </div>
          <div class="row">
            <q-select
              outlined
              bg-color="grey-3"
              color="default-pink"
              class="col-6"
              dense
              emit-value
              map-options
              v-model="wiki.category"
              :options="listCategories"
              lazy-rules
              :rules="[(val) => !!val || 'O campo categoria é obrigatório.']"
            />
            <div class="col-5 q-ml-md" style="height: 0">
              <OutlineBtn
                titleButton="Cadastrar categorias"
                :showIcon="false"
                @click="showDialogCategory = true"
              />
            </div>
          </div>
        </div>

        <div class="form-input col-6 column">
          <div class="row">
            <span class="title-input-form">Dependência</span>
          </div>
          <div class="row">
            <q-select
              v-model="wiki.dependency"
              outlined
              bg-color="grey-3"
              color="default-pink"
              class="col-11"
              dense
              use-input
              emit-value
              map-options
              input-debounce="0"
              @filter="filterFnWikis"
              :options="listWikis"
              behavior="menu"
            >
              <template v-if="wiki.dependency != null" v-slot:append>
                <q-icon
                  name="cancel"
                  @click.stop="wiki.dependency = null"
                  class="cursor-pointer"
                />
              </template>
              <template v-slot:no-option>
                <q-item>
                  <q-item-section class="text-grey">
                    Não encontrado
                  </q-item-section>
                </q-item>
              </template>
            </q-select>
          </div>
        </div>
        <div v-show="isMaster" class="form-input col-6 column">
          <div class="row">
            <span class="title-input-form"
              >Filtre os grupos com base na empresa</span
            >
          </div>
          <div class="row">
            <q-select
              outlined
              dense
              color="default-pink"
              bg-color="grey-3"
              class="col-11"
              label="Informe a empresa"
              emit-value
              map-options
              v-model="company_id"
              @update:model-value="filterGroupByCompany()"
              :options="listCompanies"
              :rules="[
                isMaster
                  ? ((val) => !!val) || 'O campo Perfil é obrigatório.'
                  : null,
              ]"
            >
              <template v-slot:no-option>
                <q-item>
                  <q-item-section class="text-grey">
                    Não encontrado
                  </q-item-section>
                </q-item>
              </template>
            </q-select>
          </div>
        </div>

        <div class="form-input col-6 column">
          <div class="row">
            <span class="title-input-form"
              >Pontuação da Wiki (Valor Padrão: {{ pointReadWiki }})
            </span>
          </div>
          <div class="row">
            <q-input
              class="col-11"
              bg-color="grey-3"
              v-model="wiki.pointRead"
              color="default-pink"
              dense
              type="text"
              outlined
            />
          </div>
        </div>
      </div>

      <div class="q-pa-sm">
        <div class="q-mb-md">
          <div class="row items-center">
            <label class="q-mr-sm">Segmentos</label>
            <q-img
              src="@/assets/icons/info_cicle.svg"
              width="16px"
              height="16px"
            />
            <q-tooltip anchor="top left" self="top left">
              Utilize os segmentos para poder filtrar os grupos
            </q-tooltip>
          </div>
          <q-select
            dense
            outlined
            color="default-pink"
            bg-color="grey-3"
            class="col-11"
            id="semgments"
            multiple
            use-chips
            use-input
            emit-value
            map-options
            @filter="filterFnBranches"
            v-model="wiki.branches"
            :options="listBranches"
          >
            <template v-slot:no-option>
              <q-item>
                <q-item-section class="text-grey">
                  Não encontrado
                </q-item-section>
              </q-item>
            </template>
          </q-select>
        </div>
        <div class="">
          <div class="row">
            <span class="title-input-form"
              >Grupos <b title="Obrigatório" style="cursor: pointer">*</b></span
            >
          </div>
          <div class="container-groups">
            <div class="item-groups">
              <q-select
                dense
                outlined
                color="default-pink"
                bg-color="grey-3"
                class="col-11"
                multiple
                use-input
                use-chips
                emit-value
                @filter="filterFnGroups"
                map-options
                v-model="wiki.group"
                :options="optionListGroups"
              >
                <template v-slot:no-option>
                  <q-item>
                    <q-item-section class="text-grey">
                      Não encontrado
                    </q-item-section>
                  </q-item>
                </template>
              </q-select>
            </div>
            <q-checkbox
              left-label
              v-model="allGroups"
              label="Selecionar todos"
              color="default-pink"
              @update:model-value="selectedFilteredGroups"
            />
          </div>
        </div>
      </div>

      <div class="col-6 flex items-end row q-pa-sm">
        <div class="form-input col-6 column">
          <div class="row">
            <span class="title-input-form"> Data de postagem </span>
          </div>
          <div class="row">
            <q-input
              class="col-11"
              bg-color="grey-3"
              color="default-pink"
              dense
              type="text"
              outlined
              v-model="wiki.date_start"
            >
              <template v-slot:append>
                <q-icon name="event" class="cursor-pointer">
                  <q-popup-proxy
                    cover
                    transition-show="scale"
                    transition-hide="scale"
                  >
                    <q-date
                      v-model="wiki.date_start"
                      mask="DD/MM/YYYY HH:mm:ss"
                    >
                      <div class="row items-center justify-end">
                        <q-btn
                          v-close-popup
                          label="Close"
                          color="primary"
                          flat
                        />
                      </div>
                    </q-date>
                  </q-popup-proxy>
                </q-icon>
                <q-icon name="access_time" class="cursor-pointer">
                  <q-popup-proxy
                    cover
                    transition-show="scale"
                    transition-hide="scale"
                  >
                    <q-time
                      v-model="wiki.date_start"
                      mask="DD/MM/YYYY HH:mm:ss"
                      format24h
                    >
                      <div class="row items-center justify-end">
                        <q-btn
                          v-close-popup
                          label="Close"
                          color="primary"
                          flat
                        />
                      </div>
                    </q-time>
                  </q-popup-proxy>
                </q-icon>
              </template>
            </q-input>
          </div>
        </div>
        <div class="form-input col-6 column">
          <q-checkbox
            v-model="wiki.edit_others"
            label="Edição compartilhada"
            color="default-pink"
          />
        </div>
      </div>

      <slot name="action-btn" v-bind:props="formRef"></slot>
    </q-form>

    <AddCategotyDialog
      title="Adicionar novas categorias para wiki"
      :listCategory="listCategories"
      :showDialog="showDialogCategory"
      :canRemove="true"
      @addNewCategory="(newCategory) => addNewCategory(newCategory)"
      @disableCategory="(category, event) => removeCategory(category, event)"
      @close="() => (showDialogCategory = false)"
    />
  </div>
</template>

<style></style>

<script>
//----ICONS

//----COMPONENTS
import AddCategotyDialog from "@/components/wiki/dialog/AddCategotyDialog.vue";
import OutlineBtn from "@/components/shared/buttons/OutlineBtn.vue";

//----SERVICES
import WikiDataServices from "@/services/WikiDataServices";
import GroupServices from "@/services/GroupServices";

//----UTILS
import AlertsClass from "@/utils/Notifications/AlertsClass";

//----VUEJS
import { ref, onMounted, watch } from "vue";
import { useStore } from "vuex";

export default {
  name: "InformationWiki",
  props: {
    wiki: Object,
  },
  components: {
    OutlineBtn,
    AddCategotyDialog,
  },

  setup(props) {
    //----CONSTANTES
    const store = useStore();
    const formRef = ref(null);

    //----SERVICES
    const wikiDataServices = new WikiDataServices();
    const groupServices = new GroupServices();

    //----UTILS
    const Alerts = new AlertsClass();

    //----VARIABLES
    let listCategories = ref([]);
    let showDialogCategory = ref(false);
    let listGroups = ref([]);
    let optionListGroups = ref([]);
    let optionListGroupsByCompany = ref([]);
    let listWikis = ref([]);
    let optionListWikis = ref([]);
    let optionListBranches = ref([]);
    let listBranches = ref([]);
    let allGroups = ref(false);

    onMounted(() => {
      listAllCategories();
      listAllGroup();
      listAllWikis();
      getBranches();
    });

    watch(
      () => props.wiki.branches,
      (newBranch, oldBranch) => {
        if (newBranch.length == 0) {
          props.wiki.group = null;
          props.wiki.group = [];
          allGroups.value = false;
        }

        if (newBranch.length != oldBranch.length) {
          optionListGroups.value = listGroups.value;
        }

        if (newBranch.length != 0) {
          optionListGroups.value = listGroups.value.filter((group) => {
            return newBranch.includes(group.branch_id);
          });

          let groupValues = optionListGroups.value.map((group) => {
            return group.value;
          });

          props.wiki.group = groupValues.filter((value) => {
            if (props.wiki.group.includes(value)) {
              return value;
            }
          });
        }
      }
    );

    watch(optionListGroups, (newGroup, oldGroup) => {
      if (allGroups.value && props.wiki.branches.length != 0) {
        if (newGroup.length > oldGroup.length) {
          optionListGroups.value.filter((group) => {
            if (!props.wiki.group.includes(group.value)) {
              props.wiki.group.push(group.value);
            }
          });
        }
      }
    });

    watch(
      () => props.wiki.group,
      (newGroup, oldGroup) => {
        if (newGroup.length == 0) {
          allGroups.value = false;
        }
      }
    );

    async function listAllWikis() {
      wikiDataServices
        .getAllWithOutUser(1, 100, store.state.userdata.company_id)
        .then((response) => {
          response.data.map((item) => {
            listWikis.value.push({
              label: item.title,
              value: item.wikiId,
            });
          });
          optionListWikis.value = listWikis.value;
        })
        .catch((e) => {
          console.log("Fail in loading categories");
        });
    }

    async function listAllCategories() {
      wikiDataServices
        .getAllCategories(store.state.userdata.company_id)
        .then((response) => {
          response.data.map((item) => {
            listCategories.value.push({
              label: item.name,
              value: item.id,
              image: item.image_path,
            });
          });
        })
        .catch((e) => {
          Alerts.alertOf("Erro ao carregar as categorias");
        });
    }

    function selectedFilteredGroups() {
      if (allGroups.value) {
        optionListGroups.value.filter((group) => {
          if (!props.wiki.group.includes(group.value)) {
            props.wiki.group.push(group.value);
          }
        });
      } else {
        props.wiki.group = null;
        props.wiki.group = [];
      }
    }

    async function listAllGroup() {
      groupServices
        .listGroups()
        .then((response) => {
          response.data.map((item) => {
            listGroups.value.push({
              label: item.group_name,
              value: item.id,
              company_id: item.company_id,
              branch_id: item.branch_id,
            });
          });

          optionListGroups.value = listGroups.value;
          optionListGroupsByCompany.value = listGroups.value;
        })
        .catch((e) => {
          console.log("Fail in loading categories");
        });
    }

    async function getBranches() {
      await groupServices
        .listBranches()
        .then((response) => {
          response.data.map((item) => {
            if (item.status) {
              listBranches.value.push({
                label: item.name_branch,
                value: item.id,
              });
            }
          });
          optionListBranches.value = listBranches.value;
        })
        .catch((err) => {
          console.log(err);
        });
    }

    function filterFnBranches(val, update) {
      if (val === "") {
        update(() => {
          listBranches.value = optionListBranches.value;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        listBranches.value = optionListBranches.value.filter(
          (v) => v.label.toLowerCase().indexOf(needle) > -1
        );
      });
    }

    function filterFnGroups(val, update) {
      if (val === "") {
        update(() => {
          if (props.wiki.branches.length > 0) {
            optionListGroups.value = listGroups.value.filter((group) => {
              return props.wiki.branches.includes(group.branch_id);
            });
          }
          optionListGroups.value = listGroups.value;
        });
      }

      update(() => {
        const needle = val.toLowerCase();
        optionListGroups.value = optionListGroups.value.filter((v) => {
          if (props.wiki.branches.length > 0) {
            return (
              v.label.toLowerCase().indexOf(needle) > -1 &&
              props.wiki.branches.includes(v.branch_id)
            );
          }
          return v.label.toLowerCase().indexOf(needle) > -1;
        });
      });
    }

    function filterGroupByCompany() {
      listGroups.value = optionListGroupsByCompany.value.filter(
        (v) => v.company_id == company_id.value
      );
      optionListGroups.value = listGroups.value;
    }

    async function addNewCategory(newCategoryName) {
      if (newCategoryName == "") {
        Alerts.alertOf("Você precisa preencher o campo nome da categoria");
        return;
      }

      if (newCategoryName.idCategory != 0) {
        const formData = new FormData();

        formData.append("name", newCategoryName.newCategoryName);
        formData.append("category_image", newCategoryName.file);

        await wikiDataServices
          .updateCategories(newCategoryName.idCategory, formData)
          .then(() => {
            listCategories.value = [];
            listAllCategories();
            Alerts.alertOf("Categoria editada com sucesso", "green-9");
          })
          .catch((error) => {
            Alerts.alertOf(error.response.data.toString());
          });
        newCategoryName = "";
      } else {
        const formData = new FormData();

        formData.append("companyId", store.state.userdata.company_id);
        formData.append("name", newCategoryName.newCategoryName);
        formData.append("category_image", newCategoryName.file);

        await wikiDataServices
          .addCategories(formData)
          .then(() => {
            listCategories.value = [];
            listAllCategories();
            Alerts.alertOf("Categoria salva com sucesso", "green-9");

            //alertNotify("Categoria salva com sucesso", "green-9");
          })
          .catch((error) => {
            Alerts.alertOf(error.data.toString());
          });
        newCategoryName = "";
      }
    }

    function removeCategory(category) {
      $q.notify({
        message: `Deseja remover o categoria ${category.label}?`,
        color: "red-9",
        position: "bottom",
        actions: [
          {
            label: "Remover",
            color: "white",
            handler: () => {
              _removeCategory(category);
            },
          },
        ],
      });
    }

    async function _removeCategory(category) {
      await wikiDataServices
        .removeCategories(category.value)
        .then(() => {
          listCategories.value = [];
          listAllCategories();
        })
        .catch((error) => {
          Alerts.alertOf("Erro ao carregar ao remover essa categoria");
        });
    }

    return {
      //----VARIABLES
      listCategories,
      showDialogCategory,
      listGroups,
      listWikis,
      optionListWikis,
      formRef,
      optionListBranches,
      listBranches,
      allGroups,
      optionListGroups,

      //----FUNCTION
      addNewCategory,
      removeCategory,
      filterGroupByCompany,
      filterFnBranches,
      filterFnGroups,
      selectedFilteredGroups,
    };
  },
};
</script>

<style lang="scss" scoped>
.container-groups {
  display: flex;
  flex-wrap: nowrap;
}

.item-groups {
  width: calc(80% - 25px);
  margin-right: 25px;
}
</style>

<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 77 58"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.8"
      d="M69.217 0.716982H7.16038C3.13267 0.716982 0 3.99882 0 7.87736V50.8396C0 54.8673 3.13267 58 7.16038 58H69.217C73.0955 58 76.3774 54.8673 76.3774 50.8396V7.87736C76.3774 3.99882 73.0955 0.716982 69.217 0.716982ZM68.3219 50.8396H8.05542C7.45873 50.8396 7.16038 50.5413 7.16038 49.9446V8.77241C7.16038 8.32488 7.45873 7.87736 8.05542 7.87736H68.3219C68.7695 7.87736 69.217 8.32488 69.217 8.77241V49.9446C69.217 50.5413 68.7695 50.8396 68.3219 50.8396ZM19.0943 13.8443C15.6633 13.8443 13.1274 16.5295 13.1274 19.8113C13.1274 23.2423 15.6633 25.7783 19.0943 25.7783C22.3762 25.7783 25.0613 23.2423 25.0613 19.8113C25.0613 16.5295 22.3762 13.8443 19.0943 13.8443ZM14.3208 43.6792H62.0566V31.7453L48.9292 18.7671C48.1834 18.0212 47.1392 18.0212 46.3933 18.7671L28.6415 36.5189L22.6745 30.7011C21.9287 29.9552 20.8844 29.9552 20.1386 30.7011L14.3208 36.5189V43.6792Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: "FileDefault",
  props: {
    width: {
      type: Number,
      default: 77,
    },
    height: {
      type: Number,
      default: 58,
    },
    color: String,
  },
  setup() {},
};
</script>

<style lang="scss" scoped></style>

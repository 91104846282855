<template>
  <div class="main-content q-pa-md">
    <Mural :breadcrumbs="breadcrumbs" route="wiki" :query="{}"> </Mural>
    <div class="q-pa-md content-new-training" v-if="!loading">
      <q-stepper
        v-model="step"
        ref="stepper"
        animated
        flat
        done-color="secondary"
        active-color="secondary"
      >
        <q-step
          v-for="page in setpPages"
          :key="page.name"
          :name="page.name"
          :title="page.title"
          :icon="page.icon"
          :done="page.done()"
        >
          <div class="content-new-training flex row col-12 lesson-content">
            <div class="content-form-training col-12">
              <div>
                <component :is="page.component" :wiki="wiki">
                  <template v-slot:action-btn="props">
                    <q-separator spaced inset />
                    <q-stepper-navigation class="flex justify-end">
                      <q-btn
                        v-if="step > 1"
                        outline
                        rounded
                        color="secondary"
                        @click="$refs.stepper.previous()"
                        label="Voltar"
                        class="q-mr-sm"
                      />
                      <q-btn
                        rounded
                        @click="
                          () => {
                            setpPages[step - 1].validade(props.props);
                          }
                        "
                        color="secondary"
                        :label="step === 4 ? 'Postar Wiki' : 'Continuar'"
                      />
                    </q-stepper-navigation>
                  </template>
                </component>
              </div>
            </div>
          </div>
        </q-step>

        <!-- <template v-slot:navigation>
          <q-separator spaced inset />
          <q-stepper-navigation class="flex justify-end">
            <q-btn
              v-if="step > 1"
              outline
              rounded
              color="secondary"
              @click="$refs.stepper.previous()"
              label="Voltar"
              class="q-mr-sm"
            />
            <q-btn
              rounded
              @click="
                () => {
                  setpPages[step - 1].validade();
                }
              "
              color="secondary"
              :label="step === 4 ? 'Postar Wiki' : 'Continuar'"
            />
          </q-stepper-navigation>
        </template> -->
      </q-stepper>
    </div>

    <q-inner-loading
      :showing="loading"
      label="Por favor aguarde..."
      label-class="text-teal"
      label-style="font-size: 1.1em"
    >
      <div class="collumn justify-center q-my-md">
        <div style="height: auto; width: 50px; margin: auto">
          <LoadingIcon />
        </div>
        <label>Por favor aguarde... {{ loadingPostValue }}%</label>
      </div>
    </q-inner-loading>
  </div>
</template>

<script>
//----COMPNENTS
import Mural from "@/components/Mural.vue";
import DefaultBtn from "@/components/shared/buttons/DefaultBtn.vue";
import InputNumber from "@/components/shared/inputs/InputNumber.vue";
import LoadingIcon from "@/components/LoadingIcon.vue";
import OutlineBtn from "@/components/shared/buttons/OutlineBtn.vue";
import AddCategotyDialog from "@/components/wiki/dialog/AddCategotyDialog.vue";

import BanerWiki from "@/components/wiki/form/BanerWiki.vue";
import InformationWiki from "@/components/wiki/form/InformationWiki.vue";
import AttachmentWiki from "@/components/wiki/form/AttachmentWiki.vue";
import ContentWiki from "@/components/wiki/form/ContentWiki.vue";

//----SERVICES
import WikiDataServices from "@/services/WikiDataServices";
import PointServices from "@/services/PointServices";
import CompanyServices from "@/services/CompanyServices";

//----UTILS
import { ENV  } from "@/utils/env";
import AlertsClass from "@/utils/Notifications/AlertsClass";

//----QUASAR
import { useQuasar, date } from "quasar";

//----VUEJS
import { useStore } from "vuex";
import { ref, reactive, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";

export default {
  name: "NewWiki",
  components: {
    Mural,
    DefaultBtn,
    InputNumber,
    LoadingIcon,
    OutlineBtn,
    AddCategotyDialog,
    BanerWiki,
    InformationWiki,
    AttachmentWiki,
    ContentWiki,
  },
  setup() {
    //----CONSTANTES
    const store = useStore();
    const $q = new useQuasar();
    const route = new useRoute();
    const router = new useRouter();
    const breadcrumbs = ref(["Adicionar wiki"]);
    const refFileInput = ref(null);
    const url_aws_bucket =ENV.URL_AWS_BUCKET;
    const user = store.state.userdata;
    const wikiId = route.query.wikiId;

    //----UTILS
    const Alerts = new AlertsClass();

    //----SERVICES
    const _pointServices = new PointServices();
    const wikiDataServices = new WikiDataServices();
    const companyServices = new CompanyServices();

    //----VARIABLES
    let loading = ref(false);
    let listCategories = ref([]);
    let loadingPostValue = ref(0);

    let listCompanies = ref([]);
    let isMaster = ref(false);
    let pointReadWiki = ref("");
    let company_id = ref(null);

    let step = ref(1);
    let setpPages = ref([
      {
        name: 1,
        done: () => {
          return step.value > 1;
        },
        title: "Banner",
        icon: "image",
        component: BanerWiki,
        validade: validadeBanner,
      },
      {
        name: 2,
        done: () => {
          return step.value > 2;
        },
        title: "Configurações",
        icon: "settings",
        component: InformationWiki,
        validade: validadeInformation,
      },
      {
        name: 3,
        done: () => {
          return step.value > 3;
        },
        title: "Anexos",
        icon: "attach_file",
        component: AttachmentWiki,
        validade: () => {
          step.value += 1;
        },
      },
      {
        name: 4,
        done: () => {
          return step.value > 4;
        },
        title: "Conteúdo",
        icon: "description",
        component: ContentWiki,
        validade: validadeContent,
      },
    ]);

    let wiki = reactive({
      banner: "",
      title: "",
      description: "",
      category: [],
      dependency: null,
      imageUrl: "",
      contents: [
        {
          section_Content: "",
          section_Title: "",
        },
      ],
      scorm: "",
      gameId: null,
      pointRead: "",
      linkgame: "",
      date_start: date.formatDate(new Date(), "DD/MM/YYYY H:mm:ss"),
      tag: [],
      group: [],
      files: [],
      anexos: [],
      Name_game: "",
      Image_game: [],
      edit_others: true,
      branches: [],
    });

    async function listAllCompanies() {
      loading.value = true;
      await companyServices
        .getAll()
        .then((response) => {
          response.data.map((item) => {
            listCompanies.value.push({
              label: item.company_name,
              value: item.id,
            });
          });
          loading.value = false;
        })
        .catch((e) => {
          loading.value = false;
        });
    }

    onMounted(async () => {
      _getEventPoint();

      isMaster.value = user.master == 1;
      // if (isMaster.value) {
      //   listAllCompanies();
      // }

      await GetWiki(route.query.wikiId);
    });

    async function _getEventPoint() {
      let EVENT_READ_POST = 4;
      let response = await _pointServices.getEvent(EVENT_READ_POST);
      pointReadWiki.value = response.data.value;
    }

    function validadeBanner() {
      wiki.banner == "" && wiki.imageUrl == ""
        ? Alerts.alertOf(
            "Para continuar tem Adicione uma imagem válida como Banner."
          )
        : (step.value += 1);
    }

    async function validadeInformation(props) {
      await props.validate().then((success) => {
        if (success) {
          step.value += 1;
        } else {
          Alerts.alertOf(
            "Para continuar preencha todos os campos obrigatórios."
          );
        }
      });
    }

    function validadeContent() {
      const elementsEmpty = wiki.contents.filter((element) => {
        return element.section_Title == "" || element.section_Content == "";
      });
      return elementsEmpty.length > 0
        ? Alerts.alertOf(
            "Para continuar tem que preencher todos os campos das seções."
          )
        : validateAndAddWiki();
    }

    async function validateAndAddWiki() {
      if (listCategories.value.length < 0) {
        Alerts.alertOf(
          "Para salvar uma wiki você tem que cadatarar uma categoria"
        );
        loading = false;
        return;
      }

      const formData = _treatmentFormDataToWiki();

      loading.value = true;

      if (wikiId == null) {
        await wikiDataServices
          .add(formData, _loadingPublish)
          .then((response) => {
            loading.value = false;
            router.push({ name: "wiki" });
          })
          .catch((error) => {
            Alerts.alertOf(error.response.data.toString());
            loading.value = false;
          });
      } else {
        await wikiDataServices
          .update(wikiId, formData, _loadingPublish)
          .then((response) => {
            loading.value = false;
            router.push({ name: "wiki" });
          })
          .catch((error) => {
            Alerts.alertOf(error.response.data.toString());
            loading.value = false;
          });
      }
    }

    function _loadingPublish(value) {
      loadingPostValue.value = value;
    }

    function _treatmentFormDataToWiki() {
      let date = wiki.date_start.split(" ");
      let dateDays = date[0].split("/").reverse().join("-");

      const formData = new FormData();

      formData.append("banner", wiki.banner);
      formData.append("title", wiki.title);
      formData.append("description", wiki.description);
      formData.append("category", wiki.category);
      formData.append("pointRead", wiki.pointRead == "" ? 0 : wiki.pointRead);
      formData.append("datetime_start", `${dateDays} ${date[1]}`);

      if (
        wikiId != null &&
        wiki.gameId != null &&
        typeof wiki.scorm == "string"
      ) {
        formData.append("Game_id", wiki.gameId);
      }

      formData.append("Status", 1);
      formData.append("Edition_other_tutor", wiki.edit_others);

      if (typeof wiki.scorm !== "string") {
        formData.append("scorm", wiki.scorm);
      }
      if (wiki.Image_game.length !== 0) {
        formData.append("Image_game", wiki.Image_game);
      }

      if (wiki.Name_game != "") {
        formData.append("Name_game", wiki.Name_game);
      }

      formData.append(
        "dependency",
        wiki.dependency == undefined ? 0 : wiki.dependency
      );

      wiki.contents.forEach((item) => {
        formData.append("Session_Title", item.section_Title);
        formData.append("contents", item.section_Content);
      });

      wiki.group.forEach((group) => {
        formData.append("group[]", group);
      });

      wiki.files.forEach((file) => {
        formData.append("files", file);
      });

      if (wiki.anexos.length > 0) {
        wiki.anexos.forEach((anexo) => {
          formData.append("anexos[]", anexo.path);
        });
      }
      return formData;
    }

    async function GetWiki(wikiId) {
      if (wikiId == null) return null;
      loading.value = true;
      await wikiDataServices
        .get(wikiId)
        .then((reponsive) => {
          wiki.title = reponsive.data.title;
          wiki.description = reponsive.data.description;
          wiki.category = reponsive.data.category_id;
          wiki.dependency =
            reponsive.data.dependency == 0 ? null : reponsive.data.dependency;
          wiki.contents = reponsive.data.contents;
          //checar novo caso
          wiki.gameId = [0, null].includes(reponsive.data.game_id)
            ? null
            : reponsive.data.game_id;
          wiki.linkgame = reponsive.data.linkgame ?? "";
          wiki.pointRead = reponsive.data.pointRead;
          wiki.edit_others = reponsive.data.edition_other_tutor;
          wiki.date_start = date.formatDate(
            reponsive.data.datetime_Start,
            "DD/MM/YYYY H:mm"
          );

          wiki.imageUrl =
            reponsive.data.banner == null
              ? ""
              : url_aws_bucket + reponsive.data.banner;

          reponsive.data.group.forEach((group) => {
            wiki.group.push(group.groupId);
          });

          reponsive.data.tag.forEach((tag) => {
            wiki.tag.push(tag.name);
          });

          wiki.anexos = reponsive.data.anexos;
          wiki.Name_game = reponsive.data.name_game;
          reponsive.data.branch_id.forEach((branch) => {
            wiki.branches.push(branch);
          });
          loading.value = false;
        })
        .catch(() => {
          $q.notify({
            type: "negative",
            message: `Erro ao carregar dados`,
          });
          loading.value = false;
        });
    }

    return {
      step,
      setpPages,
      breadcrumbs,
      pointReadWiki,
      validateAndAddWiki,
      wiki,
      loading,
      url_aws_bucket,
      refFileInput,
      listCompanies,
      company_id,
      isMaster,
      loadingPostValue,
    };
  },
};
</script>

<style lang="scss" scoped>
.main-content {
  width: 100%;
  height: 100%;

  .lesson-content {
    width: 100%;
    height: 75vh;
    overflow-y: auto;
  }
}

.card-attachment {
  background: #fbfdf7;
  border: 1px solid #e0e0e0;
}
.main-content {
  width: 100%;
  height: 100%;

  .title-form {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    //color: #000000;
    opacity: 0.45;
  }

  .content-new-training {
    height: 80%;
    overflow: auto;

    .content-thumb-training {
      height: 100%;
      .thumb-training {
        border-radius: 10px;
        .q-img {
          border-radius: 10px;
        }
      }
      .btn-thumb-training {
        .q-btn {
          text-transform: none !important;
          border-radius: 8px;
        }
      }
      .label-title-menus {
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        color: #686868;
      }
    }
    .content-form-training {
      height: 100%;

      .form-input {
        .title-input-form {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          color: #000000;
          opacity: 0.45;
        }
      }
    }
  }

  @media (min-width: 800px) {
    flex-direction: row;
  }
}
</style>

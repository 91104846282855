<template>
  <q-dialog full-height v-model="isShowDialog" persistent>
    <q-card class="dialog-main">
      <!-- <div class="text-body1">
          {{ title }}
        </div> -->
      <q-tabs
        v-model="tab"
        narrow-indicator
        active-color="white"
        class="bg-default-pink text-white"
        @update:model-value="resetValue()"
      >
        <q-tab name="add" label="Adicionar" />
        <q-tab name="edit" label="Editar" :disable="tab != 'edit'" />
      </q-tabs>

      <q-separator />

      <q-card-section class="scroll" style="height: 80%">
        <q-form
          ref="myForm"
          @submit="
            () => {
              onSubmit() ? $emit('addNewCategory', categoryForm) : '';
            }
          "
        >
          <div class="col-12">
            <label class="title-form">Capa *</label>
          </div>
          <div class="col-12 thumb-training q-mt-sm shadow-3">
            <q-img
              height="150px"
              :src="imageUrl"
              fit="contain"
              style="background-color: #e5e5e5"
            />
          </div>
          <div class="col-12 btn-thumb-training flex justify-between q-mt-md">
            <q-btn
              @click="sendEventAddImage"
              class="q-mt-xs"
              unelevated
              color="default-pink"
              text-color="default-white"
              label="Escolher imagem"
            />
            <input
              style="display: none"
              ref="refFileInput"
              @change="onEventFilePicked"
              type="file"
              name="upload"
              accept="image/*"
            />
            <q-btn
              @click="sendEventRemoveImage"
              class="q-mt-xs"
              outline
              color="default-pink"
              label="Remover"
            />
          </div>
          <div class="form-input col-6 column q-mt-md">
            <div class="row">
              <span class="title-input-form"
                >Nome da categoria
                <b title="Obrigatório" style="cursor: pointer">*</b>
              </span>
            </div>
            <div class="row items-baseline">
              <q-input
                class="col-6"
                bg-color="grey-3"
                v-model="categoryForm.newCategoryName"
                color="default-pink"
                dense
                type="text"
                outlined
                :rules="[(val) => !!val || 'O campo é obrigatório.']"
              />
              <div class="col-5 flex items-start justify-end">
                <FormButton titleButton="adicionar" submit :showIcon="false" />
                <!-- @click="
                  (event) => $emit('addNewCategory', categoryForm)
                " -->
              </div>
            </div>
          </div>

          <div class="row">
            <span class="title-input-form">Categoria adicionadas</span>
          </div>
          <div class="form-input col-12 column">
            <Table
              class="full-height"
              :list="listCategory"
              :columns="columnsTable"
              :addEdit="canRemove"
              :addRemove="canRemove"
              @edit="(category) => editCategory(category)"
              @disabled="
                (category) => {
                  $emit('disableCategory', category);
                }
              "
            >
              <template v-slot:body="props">
                <div>
                  <q-btn
                    dense
                    round
                    color="default-pink"
                    title="Editar"
                    flat
                    @click="() => editCategory(props.props.row)"
                    icon="o_edit_note"
                  />
                  <q-btn
                    dense
                    round
                    color="default-pink"
                    title="Remover"
                    flat
                    @click="
                      (category) => {
                        $emit('disableCategory', props.props.row);
                      }
                    "
                    icon="o_delete_outline"
                  />
                </div>
              </template>
            </Table>
          </div>
        </q-form>
      </q-card-section>

      <q-separator />

      <q-card-actions align="right">
        <q-btn
          flat
          label="Fechar"
          color="default-pink"
          @click="$emit('close')"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
//----COMPONENTS
import FormButton from "@/components/admin/buttons/FormButton.vue";
import DefaultBtn from "@/components/shared/buttons/DefaultBtn.vue";
import Table from "@/components/shared/tables/TableDynamic.vue";

import { ENV } from "@/utils/env";

import { ref, watch } from "vue";
import { useQuasar } from "quasar";

export default {
  name: "AddCategotyDialog",
  components: {
    FormButton,
    DefaultBtn,
    Table,
  },
  props: {
    title: String,
    listCategory: Array,
    showDialog: Boolean,
    canRemove: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["addNewCategory", "close", "disableCategory"],
  setup(props) {
    //----CONSTANTS
    const $q = useQuasar();
    const myForm = ref(null);
    const refFileInput = ref(null);
    const isShowDialog = ref(props.showDialog);
    const columnsTable = ref([
      {
        name: "label",
        required: true,
        label: "Nome",
        align: "left",
        field: (row) => row.label,
        sortable: true,
      },
      {
        name: "acao",
        align: "center",
        label: "Ações",
        field: "acoes",
        sortable: false,
      },
    ]);
    const urlAwsBucket = ENV.URL_AWS_BUCKET;

    //----VARIABLES
    let categoryForm = ref({
      newCategoryName: "",
      file: null,
      idCategory: 0,
    });
    let tab = ref("add");

    let imageUrl = ref("");
    let listCategories = ref([]);
    let newCategoryName = ref("");

    function editCategory(category) {
      categoryForm.value.idCategory = category.value;
      categoryForm.value.newCategoryName = category.label;
      imageUrl.value = urlAwsBucket + category.image;
      tab.value = "edit";
    }

    function sendEventAddImage() {
      refFileInput.value.click();
    }

    function sendEventRemoveImage() {
      imageUrl.value = "";
      refFileInput.value = null;
      categoryForm.value.file = "";
    }

    function onEventFilePicked(event) {
      const files = event.target.files;
      categoryForm.value.file = files[0];
      const filename = files[0].name;
      if (filename.lastIndexOf(".") <= 0) {
        return alert("Por favor adicione um arquivo válido");
      }
      const fileReader = new FileReader();
      fileReader.addEventListener("load", (event) => {
        imageUrl.value = fileReader.result;
      });
      fileReader.readAsDataURL(files[0]);
    }

    function resetValue() {
      categoryForm.value.newCategoryName = "";
      categoryForm.value.file = "";
      categoryForm.value.idCategory = 0;
      imageUrl.value = "";
    }

    function disabled(category) {
      $q.notify({
        message: `Deseja remover o categoria ${category.label}?`,
        color: "red-9",
        position: "bottom",
        actions: [
          {
            label: "Remover",
            color: "white",
            handler: () => {
              _removeCategory(category.label);
            },
          },
        ],
      });
    }

    function _removeCategory(label) {
      listCategories.value = listCategories.value.filter((category) => {
        return category.label != label;
      });
    }

    async function onSubmit() {
      if (!(await myForm.value.validate())) {
        $q.notify({
          color: "red-5",
          message: "Você precisa preencher todos os campos obrigatórios",
        });
        return false;
      } else {
        return true;
      }
    }

    watch(
      () => props.showDialog,
      (newState) => {
        isShowDialog.value = newState;
      }
    );

    return {
      //----CONSTANTS
      myForm,
      refFileInput,
      columnsTable,
      isShowDialog,
      //----VARIABLES
      categoryForm,
      imageUrl,
      listCategories,
      newCategoryName,
      tab,

      //----FUNCTIONS
      disabled,
      sendEventAddImage,
      sendEventRemoveImage,
      onEventFilePicked,
      editCategory,
      resetValue,
      onSubmit,
    };
  },
};
</script>

<style>
.dialog-main {
  width: 50vw;
}
</style>

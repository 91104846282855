<template>
  <div>
    <div class="col-12 flex row q-pa-sm" style="padding-bottom: 0">
      <div class="form-input col-12 column">
        <div class="row">
          <span class="title-input-form">Anexos</span>
        </div>
        <div class="row">
          <q-uploader
            url="http://localhost:8080/upload"
            color="default-pink"
            class="col-12"
            bg-color="grey-3"
            square
            flat
            bordered
            multiple
            hide-upload-btn
            v-model="wiki.files"
            @added="addFiles"
            @removed="removeFile"
            v-on:update:model-value="addFiles"
            style="max-height: 200px"
          >
            <template v-slot:list="scope">
              <div class="col-12 row">
                <div
                  class="col-3"
                  v-for="file in scope.files"
                  :key="file.__key"
                >
                  <div
                    class="q-uploader__file-header q-ma-sm row flex-center no-wrap card-attachment"
                  >
                    <div class="q-uploader__file-header-content col">
                      <div class="q-uploader__title full-width">
                        {{ file.name }}
                      </div>
                      <div
                        class="q-uploader__subtitle row items-center no-wrap"
                      >
                        {{ file.__sizeLabel }} /
                        {{ file.__progressLabel }}
                      </div>
                    </div>
                    <button
                      @click="scope.removeFile(file)"
                      class="q-btn q-btn-item non-selectable no-outline q-btn--flat q-btn--round q-btn--actionable q-focusable q-hoverable q-btn--dense"
                      tabindex="0"
                      type="button"
                    >
                      <span class="q-focus-helper"></span
                      ><span
                        class="q-btn__content text-center col items-center q-anchor--skip justify-center row"
                        ><i
                          class="q-icon notranslate material-icons"
                          aria-hidden="true"
                          role="img"
                          >clear</i
                        ></span
                      >
                    </button>
                  </div>
                </div>
              </div>
            </template>
          </q-uploader>
        </div>
      </div>
    </div>

    <div
      class="col-12 flex row q-pa-sm"
      v-show="wiki.anexos.length > 0"
      style="padding-top: 0"
    >
      <div
        class="form-input col-12"
        style="border: 1px solid #e0e0e0; border-top: 0"
      >
        <div class="col-12 row">
          <div class="col-12 q-ml-sm">
            <b>Anexos cadastrados</b>
          </div>
          <div class="col-3" v-for="(anexo, index) in wiki.anexos" :key="index">
            <div
              class="q-uploader__file-header q-ma-sm row flex-center no-wrap card-attachment"
            >
              <div class="q-uploader__file-header-content col">
                <div class="q-uploader__title full-width">
                  <a
                    :href="url_aws_bucket + anexo.path"
                    download
                    target="_blank"
                    >{{ anexo.name }}</a
                  >
                </div>
              </div>
              <button
                @click="removeAnexo(anexo)"
                class="q-btn q-btn-item non-selectable no-outline q-btn--flat q-btn--round q-btn--actionable q-focusable q-hoverable q-btn--dense"
                tabindex="0"
                type="button"
              >
                <span class="q-focus-helper"></span
                ><span
                  class="q-btn__content text-center col items-center q-anchor--skip justify-center row"
                  ><i
                    class="q-icon notranslate material-icons"
                    aria-hidden="true"
                    role="img"
                    >clear</i
                  ></span
                >
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 flex row q-pa-sm" style="padding-bottom: 0">
      <div class="form-input col-12 column">
        <div class="row q-my-md">
          <q-input
            v-model="wiki.Name_game"
            type="text"
            label="Título do conteúdo interativo"
            class="col-12"
            :rules="[
              (val) =>
                !!wiki.Name_game ||
                'O nome do conteúdo interativo não pode ser vazio',
            ]"
          />
        </div>
        <div class="row">
          <span class="title-input-form"
            >Conteúdo Interativo (Adicione um arquivo zip)</span
          >
        </div>
        <div class="row">
          <q-uploader
            url="http://localhost:8080/upload"
            color="default-pink"
            square
            flat
            bordered
            hide-upload-btn
            :filter="checkFileTypeZip"
            @rejected="onRejected"
            class="col-12"
          />
        </div>

        <div class="row q-mt-md">
          <span class="title-input-form"
            >Adicione uma foto de capa para o conteúdo interativo</span
          >
        </div>
        <div class="row">
          <q-uploader
            url="http://localhost:8080/upload"
            color="default-pink"
            square
            flat
            bordered
            hide-upload-btn
            :filter="gameImage"
            @rejected="onRejected"
            class="col-12"
          />
        </div>
      </div>
    </div>

    <div
      class="col-12 flex row q-pa-sm"
      v-if="wiki.scorm != ''"
      style="padding-top: 0"
    >
      <div
        class="form-input col-12"
        style="border: 1px solid #e0e0e0; border-top: 0"
      >
        <div class="col-12 row">
          <div class="col-12 q-ml-sm">
            <b>Scorms Adicionados</b>
          </div>
          <div class="col-3">
            <div
              class="q-uploader__file-header q-ma-sm row flex-center no-wrap card-attachment"
            >
              <div class="q-uploader__file-header-content col">
                <div class="q-uploader__title full-width">
                  <a
                    :href="url_aws_bucket + wiki.scorm.path"
                    download
                    target="_blank"
                    >{{ wiki.scorm.name }}</a
                  >
                </div>
              </div>
              <button
                @click="removeScorm(wiki.scorm)"
                class="q-btn q-btn-item non-selectable no-outline q-btn--flat q-btn--round q-btn--actionable q-focusable q-hoverable q-btn--dense"
                tabindex="0"
                type="button"
              >
                <span class="q-focus-helper"></span
                ><span
                  class="q-btn__content text-center col items-center q-anchor--skip justify-center row"
                  ><i
                    class="q-icon notranslate material-icons"
                    aria-hidden="true"
                    role="img"
                    >clear</i
                  ></span
                >
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="col-12 flex row q-pa-sm"
      v-if="wiki.linkgame != ''"
      style="padding-top: 0"
    >
      <div
        class="form-input col-12"
        style="border: 1px solid #e0e0e0; border-top: 0"
      >
        <div class="col-12 row">
          <div class="col-12 q-ml-sm"><b>Scorm cadastrado</b></div>
          <div class="col-3">
            <div
              class="q-uploader__file-header q-ma-sm row flex-center no-wrap card-attachment"
            >
              <div class="q-uploader__file-header-content col">
                <div class="q-uploader__title full-width">
                  <a
                    :href="url_aws_bucket + wiki.linkgame"
                    download
                    target="_blank"
                    >{{ wiki.linkgame }}</a
                  >
                </div>
              </div>
              <button
                @click="removeScorm()"
                class="q-btn q-btn-item non-selectable no-outline q-btn--flat q-btn--round q-btn--actionable q-focusable q-hoverable q-btn--dense"
                tabindex="0"
                type="button"
              >
                <span class="q-focus-helper"></span
                ><span
                  class="q-btn__content text-center col items-center q-anchor--skip justify-center row"
                  ><i
                    class="q-icon notranslate material-icons"
                    aria-hidden="true"
                    role="img"
                    >clear</i
                  ></span
                >
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <slot name="action-btn" v-bind:props="formRef"></slot>
  </div>
</template>

<style></style>

<script>
//----ICONS

//----UTILS
import { ENV  } from "@/utils/env";

//----VUEJS
import { ref } from "vue";

//----QUASAR
import { useQuasar } from "quasar";

export default {
  name: "AttachmentWiki",
  props: {
    wiki: Object,
  },

  components: {},

  setup(props) {
    const url_aws_bucket =ENV.URL_AWS_BUCKET;
    const $q = new useQuasar();

    function addFiles(files) {
      return files.filter((file) => {
        props.wiki.files.push(file);
        return file;
      });
    }

    function removeFile(files) {
      props.wiki.files = props.wiki.files.filter((file) => {
        return file != files[0];
      });
    }

    function removeAnexo(anexo) {
      props.wiki.anexos = props.wiki.anexos.filter((file) => {
        return file.path != anexo.path;
      });
    }

    function removeScorm() {
      props.wiki.gameId = 0;
      props.wiki.linkgame = "";
      props.wiki.scorm = "";
      props.wiki.Name_game = "";
      props.wiki.Image_game = null;
      if (props.wiki.scorm == "") {
      }
    }

    function checkFileTypeZip(files) {
      return files.filter((file) => {
        let type = file.type.split("/");
        if (type[1] === "x-zip-compressed" || file.type.includes("zip")) {
          props.wiki.scorm = files[0];
          return file;
        }
      });
    }

    function gameImage(files) {
      props.wiki.Image_game = files[0];
      return files;
    }

    function onRejected() {
      $q.notify({
        type: "negative",
        message: `O arquivo não pode ser aceito`,
      });
    }

    return {
      url_aws_bucket,

      //----FUNCTION
      addFiles,
      removeFile,
      removeAnexo,
      removeScorm,
      checkFileTypeZip,
      onRejected,
      gameImage,
    };
  },
};
</script>

<style lang="scss" scoped></style>

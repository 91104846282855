<template>
  <div class="flex column q-pa-sm">
    <div class="">
      <label class="title-form">Adicione um Banner para a sua nova Wiki </label>
    </div>
    <div class="col-6 thumb-training q-mt-sm" v-if="wiki.imageUrl != ''">
      <q-img
        class="image-banner"
        :src="wiki.imageUrl"
        :ratio="16 / 9"
        fit="contain"
      />
      
    </div>
    
    <div class="image-banner-default" v-else>
      <FileDefault
        :width="77"
        :height="58"
        color="white"
      />
    </div>
    
    <div class="col-6 btn-thumb-training q-mt-md">
      <q-btn
        @click="sendEventAddImage"
        class="q-mt-xs q-mr-xl"
        unelevated
        color="default-pink"
        text-color="default-white"
        label="Escolher imagem"
      />
      <input
        style="display: none"
        ref="refFileInput"
        @change="onEventFilePicked"
        type="file"
        name="upload"
        accept="image/*"
        
      />

      <q-btn
        @click="sendEventRemoveImage"
        class="q-mt-xs"
        outline
        color="default-pink"
        label="Remover"
      />
    </div>

    <slot name="action-btn" v-bind:props="formRef"></slot>
  </div>
</template>

<style></style>

<script>
//----ICONS
import FileDefault from '@/assets/icons/custom/FileDefault.vue'

//----VUEJS
import { ref, onMounted } from "vue";

export default {
  name: "BanerWiki",
  props: {
    wiki: Object,
  },
  components:{
    FileDefault
  },
  setup(props) {
    const imageUrl = ref(props.image);
    const refFileInput = ref(null);

    const sendEventRemoveImage = () => {
      imageUrl.value = "";
      refFileInput.value = null;
      props.wiki.banner = "";
      props.wiki.imageUrl = ""
    };

    const onEventFilePicked = (event) => {
      const files = event.target.files;
      const filename = files[0].name;
      if (filename.lastIndexOf(".") <= 0) {
        return alert("Por favor adicione um arquivo válido");
      }
      props.wiki.banner = files[0];
      addImage(files[0])

     
    };

    const sendEventAddImage = () => {
      refFileInput.value.click();
    };

    function addImage(file){
      
      const fileReader = new FileReader();

      fileReader.addEventListener("load", (event) => {
        imageUrl.value = fileReader.result;
        props.wiki.imageUrl = fileReader.result;
      });
      fileReader.readAsDataURL(file);
    }

    onMounted(() => {
      if(props.wiki.banner != ""){
        addImage(props.wiki.banner)
      }
    });

    return {
      imageUrl,
      refFileInput,

      sendEventRemoveImage,
      onEventFilePicked,
      sendEventAddImage,
    };
  },
};
</script>

<style lang="scss" scoped>
.image-banner {
  max-height: 186px;
  width: 380px;
  background-color: #e5e5e5;
}

.image-banner-default {
  height: 186px;
  width: 380px;
  background-color: #e5e5e5;
  border-radius: 10px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.title-form {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  //color: #000000;
  opacity: 0.45;
}

.thumb-training {
  border-radius: 10px;

  .q-img {
    border-radius: 10px;
  }
}
.btn-thumb-training {
  .q-btn {
    text-transform: none !important;
    border-radius: 8px;
  }
}
</style>
